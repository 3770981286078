<template>
  <section>
    <div class="text-center offset-md-4 col-md-4 mt-5 pt-4">
      <logo variation="black_full"></logo>
      <h1 class="mb-2">Download our app.</h1>
      <div class="text-center mt-2">
        <a href="https://apps.apple.com/us/app/bujin-tv/id1557275634">
          <img
            src="~@/assets/img/download_ios.png"
            alt="Download Bujin.tv on App Store"
            :style="{ width: '150px' }"
          />
        </a>
      </div>
      <div class="text-center mt-2">
        <a
          href="https://play.google.com/store/apps/details?id=com.trespixels.bujintv"
        >
          <img
            src="~@/assets/img/download_google.png"
            alt="Download Bujin.tv on App Store"
            :style="{ width: '180px' }"
          />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import { isMobile, isIOS, isAndroid } from "mobile-device-detect";
import Logo from "@/components/Logo";
export default {
  components: { Logo },
  mounted() {
    if (isMobile) {
      if (isIOS) {
        window.location.href =
          "https://apps.apple.com/us/app/bujin-tv/id1557275634";
      }
      if (isAndroid) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.trespixels.bujintv";
      }
    }
  },
};
</script>
